<template>
  <div style="margin-top: 2rem;">
    <Main style="margin-top: 2rem;">
        <Button v-on:click="handleToReturn" class="btn-return title-normal mt-1">
            <img
                :src="require(`@/static/img_events/left-arrow.png`)"
                alt="logo"
                height="15"
                width="15"
                class="mr-1 ml-2"
            />
            <span>Regresar</span>
        </Button>
        <a-row class="mt-2">
          <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" style="padding: 1rem">
            <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="state.loading">
              <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
            </a-col>
            <sdCards v-show="!state.loading">
              <a-row>
                  <a-col class="mt-2" :xxl="13" :lg="13" :md="13" :sm="24" :xs="24">
                      <h3 class="title-bold-normal">{{state.event_name}}</h3>
                  </a-col>
                  <a-col :xxl="7" :lg="7" :md="7" :sm="24" :xs="24" class="text-right">
                    <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" v-if="state.reportLoading" />
                    <Button v-on:click="downloadReport()" class="btn-add-event title-normal mt-1" v-else>
                      <img
                        :src="require(`@/static/img_events/SEJ_Eventos_excel.svg`)"
                        alt="logo"
                        height="15"
                        width="15"
                        class="mr-1 ml-2"
                      />
                        Descargar excel
                    </Button>
                  </a-col>
              </a-row>
              <a-row>
                  <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24">
                      <DataTable
                          :columns="assistanceTableColumns"
                          :data="state.assistances"
                          :totalRecords="state.totalRecords"
                          v-on:getCurrentPaginator="getDataFromPaginatorNumber"
                      />
                  </a-col>
              </a-row>
            </sdCards>
          </a-col>
        </a-row>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { reactive, defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { getAssistanceListBySubevent, getAttendanceExcelBySubevent } from '@/services/attendanceRecord';

const DataTable = defineAsyncComponent(() => import('../components/DataTable/DataTable.vue'));

const assistanceTableColumns = [
  {
    title: 'Nombre completo',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Correo electrónico',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Asistencia',
    dataIndex: 'assistance',
    key: 'assistance',
  },
];

export default {
  name: 'ListAssistance',
  components: {
    Main,
    DataTable
  },
  async setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    dispatch('changeTitleHeader', "Lista de asistencia");
    const eventId = useRoute().params.id
    const eventIdParent = useRoute().params.idEvent
    
    const getAssistance = async (page = 1) => {
      state.loading = true;
      const response = await getAssistanceListBySubevent(eventId, page);
      if (response.success) {
        state.event_name = response.data.subevent.title;
        state.assistances = response.data.assistants.data.map(assistant => {
          return {
            assistance: assistant.attendance_status  ? 'Si' : 'No',
            name: assistant.fullName,
            email: assistant.user.email
          }
        });
        state.totalRecords = response.data.assistants.total;
      }
      state.loading = false;
    }

    const downloadReport = async () => {
      state.reportLoading = true;
      const response = await getAttendanceExcelBySubevent(eventId);
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Lista_asistencia_${eventId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        Notification.error({
          message: "Error",
          description: "Ocurrió un error al descargar el archivo",
        });
      }
      state.reportLoading = false;
    }

    const state = reactive({
        searchText: "",
        showTableByRequest: true,
        assistances: [],
        totalRecords: 0,
        tableLoading: false,
        event_name: "",
        loading: true,
        reportLoading: false,
    })

    const getDataFromPaginatorNumber = (number) => {
      getAssistance(number);
    }

    getAssistance();

    const handleToReturn = () => {
      router.push({path: `/register-assistance/subevents/${eventIdParent}`});
    }

    return {
      state,
      assistanceTableColumns,
      getDataFromPaginatorNumber,
      downloadReport,
      handleToReturn
    };
  },
};
</script>
<style scoped>
.input-field {
  border-radius: 30px;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 7px;
  cursor: pointer;
}
.btn-return{
    background: none;
    border: none;
    color: #7B868C;
    cursor: pointer;
}
</style>
